<template>
  <div>
    <b-form v-if="ready">
      <div>
        <label class="mt-4" for="input-auto-complete">Nom de domaine</label>
        <b-form-input required autocomplete="off" placeholder="Entrer un nom de domaine..." value="" v-on:change="handleDomain" list="input-auto" id="input-auto-complete-list"></b-form-input>
        <b-form-datalist id="input-auto" :options="domainsNames"></b-form-datalist>
      </div>

      <div>
        <label class="mt-3" for="textarea">Commentaire</label>
        <b-form-textarea
            id="textarea"
            placeholder="Entrer votre type de demande..."
            rows="3"
            max-rows="6"
            v-on:change="handleComment"
            value=""
        ></b-form-textarea>
      </div>

      <div v-if="!isLoad">
        <b-button class="mt-4"  type="submit" variant="primary" v-on:click="handleSubmit">
          Envoyer
        </b-button>
      </div>
      <div v-if="isLoad">
        <b-button class="mt-4"  variant="primary">
          Chargement
        </b-button>
      </div>

    </b-form>
    <div class="container" v-else>
      <div><AppPulsingIcon /></div>
    </div>
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import {mapState} from "vuex";
import AppPulsingIcon from "../AppPulsingIcon";

export default {

  data() {
    return {
      ready: false,
      domain : '',
      comment : '',
      isLoad : false
    };
  },

  components: {AppPulsingIcon},
  computed: {
    ...mapState({
      domainsNames: (state) => state.domain.domainsNames,
    }),
  },
  async created() {
    await this.loadDomainsNames();
  },

  validations: {
    sa: {
      name: {required},
      tokenSignatureKey: {required},
    },
  },
  methods: {

    async loadDomainsNames() {
      try {
        await this.$store.dispatch("domain/fetchDomainsNames");
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la récupération des noms de domaines",
          text: e.message,
        });
      }
      this.ready = true;
    },

    async handleSubmit() {
      if(!this.domain) return;

      this.isLoad = true;

      try {
        await this.$store.dispatch("tickets/createTicket", {
          domain : this.domain,
          comment : this.comment,
        });
      } catch (e) {
        this.handleDone(e);
        return;
      }
      this.handleDone();
    },

    handleDomain(value) {
      this.domain = value;
    },

    handleComment(value) {
      this.comment = value;
    },

    handleDone(e) {
      this.isLoad = false

      if(e) {
        this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la création du ticket",
          text: `${e.message}, assurez vous de ne pas avoir crée un ticket sur un domaine inexistant`,
        });
        return;
      }
      this.$swal.fire({
        icon: "success",
        title: "Parfait",
        text: "Votre ticket a été créé avec succès"
      });
    }
  }
}


</script>

<style scoped>
button {
  border-color: #e9ecef;
}

input#token-signature-key-input {
  filter: blur(3px);
  transition: 0.5s -webkit-filter ease;
}

input#token-signature-key-input:hover {
  filter: blur(0);
}
.container{
  margin-top: 3em;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
