<template>
  <AddTicketForm/>
</template>

<script>
import AddTicketForm from "@/components/tickets/AddTicketForm";

export default {
  components: {
    AddTicketForm,
  },
};
</script>

